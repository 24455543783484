
import { clientService } from "../../../api/axios-client-api";
import { FormValidationStatsResponse } from "./types";
import api from "../../../api/client-api";

const misApi = clientService.misApi.v1

export const  getFormValidationStats = (period): Promise<FormValidationStatsResponse> => {
  return misApi.Get("/stats/formvalidation/" + period);
}

export const getFormUXStats = (period) => {
  return api.Get("/stats/formvalidation/" + period);
}

export const getDeletedReason = (period) => {
  return misApi.Get(`/lending/applications/deletedreason/${period}`);
}


