import { ParamsType, DeletedCustomersReportResponse } from "./types";
import qs from 'qs'
import { apiV3 } from "../../../api/client-api";


export const getDeletedCutomersReport = async (
  filter: ParamsType
): Promise<DeletedCustomersReportResponse> => {
  return apiV3.Get(`audit/deleted-customers?${qs.stringify(filter)}`); 
}
