import api from '../../../api/client-api';

export const testFile = (values) => {
  const serviceUrl = '/import-files/testing';
  return api.Post(serviceUrl, values);
}

export const importFile = (values) => {
    const serviceUrl = '/import-files';
    return api.Post(serviceUrl, values);
}

export const getAllFiles = () => {
  try{
    const serviceUrl = '/import-files';
    return api.Get(serviceUrl);
  }catch(err){
    console.log("error::::::", err)
  }
  
}

export const getFilesRows = (id) => {
  try{
    const serviceUrl = `/import-rows/files/${id}`;
    return api.Get(serviceUrl);
  }catch(err){
    console.log("error::::::", err)
  }
}

export const downloadUnprocessedRows = (id) => {
  try{
    const serviceUrl = `/import-rows/files/${id}/download`;
    return api.Get(serviceUrl);
  }catch(err){
    console.log("error::::::", err)
  }
}

export const processFileById = (id) => {
  try{
    const serviceUrl = `/import-files/${id}/reconciliation/start`
    return api.Put(serviceUrl)
  }catch(err){
    console.log("error::::::", err)
  }
}

export const createContractPaidByRowId = (rowId: String) => {
  const serviceUrl = `/import-rows/${rowId}/paid`;
  return api.Post(serviceUrl);
}


export const createContractPaidByFileId = (fileId: String) => {
  const serviceUrl = `/import-rows/files/${fileId}/paid`;
  return api.Post(serviceUrl);
}