import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UpsellListContainer from '../../../../Containers/UpsellBanner/list'
import { GetAllUpsellBanners } from '../../../../services/api/dory'
import { UpsellBanner } from '../../../../services/api/dory/type'

const UpsellList = ({ ns }) => {
  let history = useHistory();
  const [data, setData] = useState<UpsellBanner[]>([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData()
  }, [])
  

  const getData = async () => {
    try {
      const response = await GetAllUpsellBanners()
      setData(response)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleGoToEditUpsell = (data: UpsellBanner): void => {
    history.push(`/upsell/edit/${data._id}`);
  }

  return (
    <UpsellListContainer 
      ns={ns} 
      data={data}
      handleGoToEditUpsell={handleGoToEditUpsell}
      loading={loading}
      history={history}
    />
  )
}

export default UpsellList