import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import BlockUi from 'react-block-ui';
import CSVReader from 'react-csv-reader'
import { Button, Jumbotron } from 'react-bootstrap';
import { FzCard, FzRow, FzCol } from '../../../fz/layout/index';
import FieldConnected from "../../../components/form/field-connected"
import { getAllSuppliers, postPayoutInfo } from '../../../../api/suppliers'
import { uploadLayoutForm } from '../../../../biz/metadatas/uploadLayout';
import { GetAllInputLayout } from '../../../../services/api/inputlayout'
import TestingFile from '../../../../components/TestingFile';
import { testFile, importFile } from '../../../../services/api/importFile';
import { FzToast } from '../../../fz/form/notification/toast';
import { getAllProducts } from '../../../../api/applications';

const UploadLayout = (props) => {
  const { ns } = props
  const [blockUI, setBlockUI] = useState(false);
  const [metaAndData, setMetaAndData] = useState({ headers: [], data: [] });
  const [loading, setLoading] = useState(true);
  const [requestState, setRequesState] = useState("");
  const [file, setFile]: any = useState();
  const [showToast, setShowToast] = useState(false)
  const [toastType, setToastType] = useState('warning')
  const [toastMessage, setToastMessage] = useState('')
  const [allLayouts, setAllLayouts]: any = useState();
  const [unfilteredLayoutList, setUnfilteredLayoutList] = useState();

  let history = useHistory();

  useEffect(() => {
    ns.clear()
    buildSuppliersList();
    buildLayoutsList();
    GetAllProductsOptions();
  }, [])

  const buildSuppliersList = () => {
    const { ns } = props;
    getAllSuppliers()
      .then((suppliers) => {
        const suppliersList = suppliers.map(x => ({
          code: x.internalName,
          description: x.name
        }))
        ns.set("suppliersList", suppliersList)
      })
      .catch((err) => {
        console.warn("API ERROR", err);
      })
      .finally(() => setRequesState("idle"));
  }

  const GetAllProductsOptions = async () => {
    try {
      const products = await getAllProducts()
      const productList = products.map(x => ({
        code: x.internalName,
        description: x.name,
      }))
      ns.set("productList", productList)

    } catch (error) {
      console.warn("API ERROR", error);
    }
  }

  const buildLayoutsList = () => {
    const { ns } = props;
    GetAllInputLayout()
      .then((layouts) => {
        const layoutsData = layouts.data
        setAllLayouts(layoutsData)
        const layoutsList = layoutsData.map(x => ({
          code: x.id,
          description: x.name,
          path: x.supplierInternalName
        }))
        ns.set("layoutsList", layoutsList)
        setUnfilteredLayoutList(layoutsList)
      })
      .catch((err) => {
        console.warn("API ERROR", err);
      })
      .finally(() => setRequesState("idle"));
  }

  const filterLayouts = (supplierInternalName) => {
    const layouts = JSON.parse(JSON.stringify(unfilteredLayoutList))

    const layoutsFilter = layouts.filter(layout => layout.path === supplierInternalName)
    if (layoutsFilter) {
      ns.saveChange("layoutsList", layoutsFilter)
    }
  }

  const isValidForSubmit = () => {
    let { ns } = props
    let uploadLayoutPayload = ns.getChanged() || ns.get("uploadLayoutPayload")
    if (uploadLayoutPayload) {
      return (
        uploadLayoutPayload.product != "undefined",
        uploadLayoutPayload.supplierInternalName != "undefined",
        uploadLayoutPayload.layout != "undefined",
        uploadLayoutPayload.referenceDate != null)
    }
  }

  const loadFile = async (event) => {
    try {
      const selectedFile = event.target.files[0]
      setFile(selectedFile);

      const layoutInfo = allLayouts.filter((index) => index.id === uploadLayoutPayload.layout)

      const response: any = await loadFileReader(selectedFile);
      let formatedFile = response.result.split(/\r?\n/).map(function (line) {
        return line.split(layoutInfo[0].separator);
      });

      setLoading(true)
      const payload = { "layoutId": ns.getChanged("layout"), "rows": formatedFile.filter((file, index) => {
        return index < 10 && file.toString() != ""
      })
    }  
      const testResult = await testFile(payload)

      if (testResult) {
        setMetaAndData(testResult)
        setLoading(false)
      }
    } catch (err) {
      alert(err)
    }
  }

  const loadFileReader = (file) => new Promise((resolve, reject) => {
 try{
    const fr = new FileReader();
    fr.onload = () => resolve(fr);
    fr.onerror = (err) => reject(err);
    fr.readAsText(file);
    }catch(err){
      setToastMessage('Nâo foi possível ler o arquivo.')
      setToastType('danger')
      setShowToast(true)
    }
  });

  const sendUpload = async () => {
    try {
      let { ns, api } = props
      let uploadLayoutPayload = ns.getChanged() || ns.get("uploadLayoutPayload")
      if (uploadLayoutPayload) {

        const formData = new FormData();

        formData.append("uploadFile", file)
        formData.append("mimeType", 'csv')
        formData.append("fileName", file.name)
        formData.append("supplierInternalName", uploadLayoutPayload.supplierInternalName)
        formData.append("product", uploadLayoutPayload.product)
        formData.append("layoutId", uploadLayoutPayload.layout)
        formData.append("referenceDate", uploadLayoutPayload.referenceDate.toISOString())

        const data = await importFile(formData)

        setToastMessage(data.TotalRows + ' Linhas Processadas.')
        setToastType('success')
        setShowToast(true)
        setTimeout(function () {
          history.push("/input-layout/upload")
        }, 2000);
      }
    } catch (err) {
      setToastMessage('Erro')
      setToastType('danger')
      setShowToast(true)
    }

  }

  const goBack = () => {
    ns.stopEditing();
    history.push("/input-layout/upload");
  }

  const handleToastClose = () => setShowToast(false)

  let uploadLayoutPayload = ns.getChanged() || ns.get("uploadLayoutPayload") || {}

  const suppliersList = ns.get("suppliersList")

  if (uploadLayoutForm.supplierInternalName.inputProps) {
    uploadLayoutForm.supplierInternalName.inputProps.options = suppliersList
  }
  if (!suppliersList) {
    return false
  }

  const layoutsList =  ns.getChanged("layoutsList") || ns.get("layoutsList")

  if (uploadLayoutForm.layout.inputProps) {
    uploadLayoutForm.layout.inputProps.options = layoutsList
  }

  if (uploadLayoutForm.product.inputProps) {
    uploadLayoutForm.product.inputProps.options = ns.getChanged("productList") || ns.get("productList")
  }
  if (!layoutsList) {
    return false
  }

  return (
    <BlockUi tag="div" blocking={blockUI}>
      <Jumbotron>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzCard.Title componentClass="h3">Upload File</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            <FzRow>
              <FzCol><FieldConnected ns={ns} meta={uploadLayoutForm.product} data={uploadLayoutPayload} /></FzCol>
              <FzCol><FieldConnected ns={ns} meta={uploadLayoutForm.supplierInternalName} data={uploadLayoutPayload} extraChangeEffect={filterLayouts} /></FzCol>
            </FzRow>
            <FzRow>
              <FzCol><FieldConnected ns={ns} meta={uploadLayoutForm.referenceDate} data={uploadLayoutPayload} /></FzCol>
              <FzCol><FieldConnected ns={ns} meta={uploadLayoutForm.layout} data={uploadLayoutPayload} /></FzCol>
            </FzRow>
            <input type="file" onChange={loadFile} disabled={!isValidForSubmit()}></input>
          </FzCard.Body>
        </FzCard>
        {!loading && <TestingFile ns={ns} metaAndData={metaAndData} loading={false} />}
        <Button variant="primary" onClick={sendUpload} disabled={!isValidForSubmit()} className='mt-2'>Prosseguir</Button>
        <Button variant="default" onClick={goBack} className='mt-2 ml-1'>Cancelar</Button>
      </Jumbotron>
      <FzToast
        fzStyle={toastType}
        show={showToast}
        delay={5000}
        headerMessageTost="Input-layout"
        bodyMessageTost={toastMessage}
        autoHide={true}
        close={handleToastClose}
      />
    </BlockUi>
  );
}

export default UploadLayout;

