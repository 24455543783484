import { getCookie, getGAuthToken, clearAuth, getLogoutURL } from './helpers';
import { getCurrentUser } from './auth';
import ClientDomains from './client-domains'
import moment from 'moment';
import axios from 'axios';

export class FzAxiosClientApi {

  constructor(baseTarget = "api/v2/", devPort = 4000, ignoreBaseTarget, isStaticClient = false) {
    // Could use webpack proxy but this need our local go server to understand /api/* urls
    window.finanzero = window.finanzero ? window.finanzero : {};
    const isBeta = /((beta)|(^home\.)|(finan0\.))/i.test(window.location.hostname);
    const isDev = /(local)|(192.168.0)/i.test(window.location.hostname);
    this.axiosInstance = axios.create();
    let subdomain = 'hub.';
    if (isDev) {
      this.baseUrl = 'http://localhost:' + devPort + '/' + (ignoreBaseTarget ? '' : baseTarget);
    } else {
      if (isBeta) {
        subdomain = 'hub-beta.';

        if (isStaticClient) {
          subdomain = 'beta.';
        }
      } else {
        if (isStaticClient) {
          subdomain = '';
        }
      }

      this.baseUrl = `https://${subdomain}finanzero.com.br/` + baseTarget;
    }

    window.finanzero.isTest = isDev || isBeta;
    window.finanzero.isBeta = isBeta;
    window.finanzero.isProd = !isDev && !isBeta;
    this.baseStaticFilesUrl = isDev || isBeta ? 'https://beta.finanzero.com.br' : 'https://finanzero.com.br';
    this.DefaultPage = 20;
    this.DefaultAppStack = 200;

    if (baseTarget.startsWith('api/v2')) {
      this.domains = new ClientDomains(this);
    }

    this.axiosInstance.defaults.baseURL = this.baseUrl;
    this.axiosInstance.defaults.headers.common['Authorization'] = getCookie('fz-cof-token');
    this.axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
    this.axiosInstance.defaults.headers.common['fz-gauth-authorization'] = getGAuthToken();

    this.axiosInstance.interceptors.response.use(this.handleSuccess, this.handleError.bind(this));
    this.axiosInstance.interceptors.request.use(this.handleRequest);
    this.clearAuthDefaultHeaders = this.clearAuthDefaultHeaders.bind(this)
  }

  handleRequest(config) {
    if (!config?.headers?.common['Authorization']) {
      config.headers.common['Authorization'] = getCookie('fz-cof-token');
    }
    if (!config?.headers?.common['fz-gauth-authorization']) {
      config.headers.common['fz-gauth-authorization'] = getGAuthToken();
    }
    return config
  }

  handleSuccess(response) {
    return response
  }

  clearAuthDefaultHeaders() {
    if (this?.axiosInstance?.defaults?.headers?.common["Authorization"]) {
      this.axiosInstance.defaults.headers.common["Authorization"] = ""
    }
    if (this?.axiosInstance?.defaults?.headers?.common["fz-gauth-authorization"]) {
      this.axiosInstance.defaults.headers.common["fz-gauth-authorization"] = ""
    }
  }


  handleError(error) {
    if ([403, 401].includes(error?.response?.status)) {
      clearAuth()
      window.location.href = getLogoutURL(window.location)
      this.clearAuthDefaultHeaders()
    }
    return Promise.reject(error)
  }

  _buildQueryString(filter) {
    var queryFilter = (query, value) => {
      return ((value !== undefined && value !== null && value.length > 0) ? ('&' + query + '=' + encodeURIComponent(value)) : '')
    }

    var isObject = (object) => {
      return Object.keys(object).reduce((prev, curr) => {
        let value = object[curr];
        return value && value.length > 0 ? prev + queryFilter(curr, value) : prev
      }, "")
    }

    var queryString = ""
    for (var f in filter) {
      if (filter.hasOwnProperty(f)) {

        var value = filter[f] instanceof moment ? filter[f].toISOString() : filter[f]

        if (value instanceof Object && !Array.isArray(value)) {
          queryString += isObject(value)
        } else {
          queryString += queryFilter(f, value)

        }
      }
    }
    return (queryString);
  }

  _post(serviceUrl, data) {
    return this.axiosInstance.post(this._apiUrl(serviceUrl), data)
  }

  Post(serviceUrl, data) {
    return this._post(serviceUrl, data)
  }

  _get(serviceUrl) {
    return this.axiosInstance.get(this._apiUrl(serviceUrl))
  }

  Get(serviceUrl, queryObject) {
    const queryString = this._buildQueryString(queryObject);
    return this._get(serviceUrl + (queryString ? `?${queryString}` : ""))
  }

  _put(serviceUrl, data) {
    return this.axiosInstance.put(this._apiUrl(serviceUrl), data)
  }

  Put(serviceUrl, data) {
    return this._put(serviceUrl, data)
  }

  _delete(serviceUrl) {
    return this.axiosInstance.delete(this._apiUrl(serviceUrl))
  }

  Delete(serviceUrl) {
    return this._delete(serviceUrl)
  }

  _apiUrl(serviceUrl) {
    if (serviceUrl[0] === "/") {
      return this.baseUrl + serviceUrl.slice(1);
    }
    return this.baseUrl + serviceUrl;
  }
}

//TODO _ remover 
var screeningApi = !screeningApi ? new FzAxiosClientApi("screening/v1/", 3023) : screeningApi
var unicoApi = !unicoApi ? new FzAxiosClientApi("unico/v1/", 3006) : unicoApi
var hsApi = !hsApi ? new FzAxiosClientApi("hs/v2/", 3007) : hsApi
var misApi = !misApi ? new FzAxiosClientApi("mis/v1/", 3020) : misApi
var bureauApi = !bureauApi ? new FzAxiosClientApi("bureau/v1/", 4001) : bureauApi
var currentApi = !currentApi ? new FzAxiosClientApi("api/v2/", 4000, true) : currentApi
var doryApi = !doryApi ? new FzAxiosClientApi("dory/v2/", 3027, true) : doryApi
var finanvcApi = !finanvcApi ? new FzAxiosClientApi("finanvc/v1/", 0) : finanvcApi

const buildAxiosClientAPI = (path = "api/v2/", port = 4000, insertUserData = false, isStaticClient = false) => {
  let instanceAxiosClientApi = new FzAxiosClientApi(path, port, false, isStaticClient)
  if (insertUserData) {
    let user = getCurrentUser()
    if (user) {
      instanceAxiosClientApi.axiosInstance.defaults.headers.common['user-id'] = user.id
      instanceAxiosClientApi.axiosInstance.defaults.headers.common['user-name'] = user.name
    }
  }
  return instanceAxiosClientApi
}

const clientService = {
  screeningApi: {
    v1: buildAxiosClientAPI("screening/v1/", 3023),
  },
  misApi: {
    v1: buildAxiosClientAPI("mis/v1/", 3020),
  },
  bureauApi: {
    v1: buildAxiosClientAPI("bureau/v1/", 4001),
  },
  hsApi: {
    v1: buildAxiosClientAPI("hs/v2/", 3007),
  },
  unicoApi: {
    v1: buildAxiosClientAPI("unico/v1/", 3006),
  },
  // api: {
  //   v2: buildAxiosClientAPI("api/v2/", 4000),
  //   v3: buildAxiosClientAPI("api/v3/", 4000),
  // },
  doryApi: {
    v1: buildAxiosClientAPI("dory/v1/", 3027),
  },
  hermesApi: {
    v1: buildAxiosClientAPI("hermes/v1/", 3028, true),
  },
  finanvcApi: {
    v1: buildAxiosClientAPI("finanvc/v1/", 3010, true)
  },
  paratiApi: buildAxiosClientAPI("me/", 3001, false, true),
}

export {
  clientService,
  screeningApi,
  unicoApi,
  hsApi,
  misApi,
  bureauApi,
  // currentApi,
  doryApi,
}
