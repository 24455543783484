import React from 'react'
import EntityPack from '../../components/lazyWay';
import { PathnameColumns, PathnameForm } from '../../../biz/metadatas/Pathname';
import { PutPathname, PostPathname, GetIdPathname, DeletePathname, GetAllPathname, GetAllFilter, GetAllUpsellBanners } from '../../../services/api/dory/index';
import { GetParatiJSON } from '../../../services/api/parati';

const PathnamePack = ({ ns }) => {

  const GetAllFiltersAndBannersOptions = async () => {
    const response = await Promise.all([GetAllFilter(), GetAllUpsellBanners({ active: true }), GetParatiJSON()])
    response.forEach((item, index) => {
      const which = ["filters", "upsell-banners", "parati"][index]

      if(which === "parati") {
        if(item.components) {
          ns.setShared(`CACHE.domains.components`, item.components.map((component: string) => ({code: component, description: component})))
        } 
        if(item.componentVariants) {
          const variants = Object.keys(item.componentVariants).reduce(
            (acc, curr) => {
              const options = item.componentVariants[curr].map(variant => (
                {code: variant, description: variant, filter: curr}
              ));

              return Array.isArray(acc) ? [...acc, ...options] : options
            }, []
          )

          ns.setShared(`CACHE.domains.componentVariants`, variants)
        }
      } else {
        ns.setShared(`CACHE.domains.${which}`, item?.map(({ name }) => ({ code: name, description: name })))
      }
    })
  }
  return EntityPack({
    ns,
    metaList: PathnameColumns,
    meta: PathnameForm,
    title:"pathname",
    entity:"pathname",
    linkPath: "_id",
    onCreate: GetAllFiltersAndBannersOptions,
    handleUpdate: PutPathname,
    actionCreate: PostPathname,
    actionGet: GetIdPathname,
    actionGetList: GetAllPathname,
    actionUpdate: PutPathname
  })
}






export default PathnamePack;
