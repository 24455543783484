import React, { useEffect, useState } from 'react';
import ContractListContainer from '../../../../Containers/Contract/list';
import { GetAllContracts, PutContract } from '../../../../services/api/contract/contract';
import { ContractResponse } from '../../../../services/api/contract/types';
import { get as getLodash } from 'lodash'
import { getAllSuppliers } from '../../../../api/suppliers';
import { getAllProducts } from "../../../../api/applications";

const toastInitialConfig = {
  show: false,
  headerMessageTost: 'Error',
  bodyMessageTost: 'Erro interno, tente novamente!',
  fzStyle: 'danger',
  delay: 5000,
  autoHide: true,
}

const initialFilter = {
  applicationid: '',
  personalnumber: '',
  product: '',
  supplierinternalname: '',
}

const Contract = ({ ns }) => {
  const [data, setData] = useState<ContractResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [toastInfo, setToastInfo] = useState(toastInitialConfig);
  const [options, setOptions] = useState<any>({
    suppliers: [],
    products: [],
  });

  useEffect(() => {
    getData();
    filterForm();
  }, []);

  const getData = async (query = {}, page = 1, sizePerPage = 50) => {
    try {
      const response = await GetAllContracts(page, sizePerPage, query);
      setData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUpdateContract = async (data: ContractResponse) => {
    try {
      const id = getLodash(data, ['id'], null)
      let status = getLodash(data, ['status'], "")
      if(status === "" || status === "active") {
        data.status = "inactive"
      } else {
        data.status = "active"
      }
      await PutContract(id, data);
      const messageResult = (
        data.status === "inactive" 
          ? "inativado" 
          : "ativado" 
      )
      setToastInfo({
        ...toastInitialConfig,
        fzStyle: 'success',
        headerMessageTost: 'Sucesso',
        bodyMessageTost: `Contrato ${messageResult} com sucesso!`,
        show: true,
      });
    } catch (error) {
      setToastInfo({
        ...toastInitialConfig,
        show: true,
      });
    } finally {
      const query = ns.getChanged() || {}
      await getData(query)
    }
  };

  const handleToastClose = () => {
    setToastInfo({...toastInfo, show: false});
  }

  const filterForm = async () => {
    ns.set("filters-contract", {});
    ns.startEditing({});
    try {
      const suppliersList = await getAllSuppliers()
      const products = await getAllProducts()
      const parseRes = (code, description) => (item) => ({ 
        code: item[code], 
        description: item[description],
      })

      setOptions({
        suppliers: suppliersList.map(parseRes("internalName", "name")),
        products: products.map(parseRes("internalName", "name"))
      });
      setLoading(false)
    } catch (error) {
      setToastInfo({
        ...toastInitialConfig,
        show: true,
      });
    }
  }

  const filters = ns.getShared("filters-contract") || ns.getChanged()

  const handleSearchContract = async () => {
    const query = ns.getChanged() || {}
    try {
      await getData(query)
    } catch (error) {
      setToastInfo({
        ...toastInitialConfig,
        show: true,
      });
    }
  }

  const clearFilters = async () => {
    try {
      ns.set("filters-contract", initialFilter);
      ns.startEditing(initialFilter);
      await getData()
    } catch (error) {
      setToastInfo({
        ...toastInitialConfig,
        show: true,
      });
    }
  }


  const handleOnChangePage = async (page = 1, sizePerPage = 50) => {
    try {
      await getData({}, page, sizePerPage)
    } catch (error) {
      setToastInfo({
        ...toastInitialConfig,
        show: true,
      });
    }
  }

  return (
    <ContractListContainer
      ns={ns}
      data={data}
      handleUpdateContract={handleUpdateContract}
      loading={loading}
      toastInfo={toastInfo}
      handleToastClose={handleToastClose}
      onPageChange={handleOnChangePage}
      filters={filters}
      options={options}
      handleSearchContract={handleSearchContract}
      clearFilters={clearFilters}
    />
  );
};

export default Contract