import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { downloadUnprocessedRows, getAllFiles, processFileById, createContractPaidByFileId } from '../../../../services/api/importFile'
import BlockUi from 'react-block-ui'
import { FzCard } from '../../../../ui/fz/layout/FzCard'
import { FzTable } from '../../../../ui/fz/grid/table'
import { FzButton } from '../../../../ui/fz/form'
import { listFilesColumns } from '../../../../biz/metadatas/uploadLayout'
import SplitLine from '../../../../components/split-line'
import fileDownload from 'js-file-download'
import { FzToast } from '../../../../ui/fz/form/notification/toast';

const toastInitialConfig = {
  show: false,
  headerMessageTost: 'Error',
  bodyMessageTost: 'Não foi possível marca pago e gerar contratos!',
  fzStyle: 'danger',
  delay: 5000,
  autoHide: true,
}

const InputLayoutListFiles = ({ ns }) => {
  let history = useHistory();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [toastInfo, setToastInfo] = useState(toastInitialConfig);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const response = await getAllFiles()
      setData(response)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const processFile = async (fileId) => {
    const result = await processFileById(fileId)
    if(result.data == "OK") {
      getData()
    }
  }

  const handleToastClose = () =>
    setToastInfo(toastInitialConfig);

  const downloadCSV = async (fileId) => {
    const result = await downloadUnprocessedRows(fileId)
    if(result && result.data){
      fileDownload("\uFEFF" + result.data, "export.csv", 'text/csv');
    }
  }

  const setPaid = async (fileId) => {
    try {
      const result = await createContractPaidByFileId(fileId)
      setToastInfo({ 
        ...toastInfo,
        fzStyle: "success",
        show: true,
        headerMessageTost: 'Marcação Finalizada!',
        bodyMessageTost: `Contratos gerados: ${result.data.TotalSuccess}\nContratos não gerados: ${result.data.TotalError}`,
      });
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setToastInfo({ ...toastInfo, show: true });    
    }
  }

  const goToInputLayoutRows = (index, row) => {
    return <>
      <FzButton
        customStyle={{
          borderColor: '#5923DD',
          color: '#fff',
          background: '#5923DD',
        }}
        size="small"
        fzStyle=""
        disabled={row.status !== 'done'}
        onClick={() =>  history.push(`/input-layout/list-rows/${row.id}`)}
      >
        Análise da Conciliação
      </FzButton>
      <FzButton
        customStyle={{
          borderColor: '#5923DD',
          color: '#fff',
          background: '#5923DD',
          marginLeft: '10px'
        }}
        size="small"
        fzStyle=""
        disabled={row.status == 'done' || row.status == 'processing'}
        onClick={() =>  processFile(row.id)}
      >
        Iniciar Conciliação
      </FzButton>
    </>
  }

  const createDownloadBtn = (index, row) => {
      return <FzButton
        customStyle={{
          borderColor: '#5923DD',
          color: '#fff',
          background: '#5923DD',
        }}
        size="small"
        fzStyle=""
        disabled={row.status !== 'done'}
        onClick={() =>  downloadCSV(row.id)}
      >
        Download
      </FzButton>
  }

  const createPayBtn = (index, row) => {
    return <FzButton
      customStyle={{
        borderColor: '#5923DD',
        color: '#fff',
        background: '#5923DD',
      }}
      size="small"
      fzStyle=""
      disabled={row.TotalFound === 0}
      onClick={() =>  setPaid(row.id)}
    >
      Marcar Pago
    </FzButton>
}

  return (
      <BlockUi tag="div" blocking={loading} message="Processando informações...">
        <div style={{ display: "flex", padding: "20px" }}>
          <FzCard>
            <FzCard.Header>
              <FzCard.Title>
                <SplitLine>
                  <SplitLine.Left>
                    Imported File List
                  </SplitLine.Left>
                  <SplitLine.Right>
                    <FzButton
                      size="small"
                      fzStyle="regular"
                      onClick={() => history.push("/input-layout/upload")}
                    >
                     Nova Importação
                    </FzButton>
                  </SplitLine.Right>
                </SplitLine>
              </FzCard.Title>
            </FzCard.Header>
            <FzCard.Body>
              <FzTable
                data={data}
                metas={listFilesColumns}
                ns={ns}
                exportDownload={false}
                pagination={true}
                clipboard={false}
                configSort={{
                  orderBy: 'created',
                  sortOrder: 'asc',
                }}
                customColumnFormatters={{
                  action: (cell, row) => goToInputLayoutRows(cell, row),
                  export: (cell, row) => createDownloadBtn(cell, row),
                  marcar_pago: (cell, row) => createPayBtn(cell, row),
                }}
              />
            </FzCard.Body>
          </FzCard>
        </div>
        <FzToast {...toastInfo} close={handleToastClose} />
      </BlockUi>
    )
  }

export default InputLayoutListFiles
