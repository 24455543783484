import api from "../../../api/client-api";

export const GetAllOCRModels = async (): Promise<any> => {
  const serviceUrl = `ocrmodels`;
  return api.Get(serviceUrl);
}

export const PutOCRModel = async (id, data): Promise<any> => {
  const serviceUrl = `ocrmodels/${id}`;
  return api.Put(serviceUrl, data);
}

export const GetOCRModelById = async (id): Promise<any> => {
  const serviceUrl = `ocrmodels/${id}`;
  return api.Get(serviceUrl);
}

export const PostOCRModel = async (data): Promise<any> => {
  const serviceUrl = `ocrmodels`;
  return api.Post(serviceUrl, data);
}

export const DeleteOCRModel = async (id): Promise<any> => {
  const serviceUrl = `ocrmodels/${id}`;
  return api.Delete(serviceUrl);
}